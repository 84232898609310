
// import SideLogo from "../images/sidelogo.svg"
import new_sidelogo from "../images/new_sidelogo.png"
import Graphic from "../images/graphic.svg"

export default function How(props) {
  return (
    <>
      <div className="how-title">
        <div className="logo-how-page">
          <img 
            // src={SideLogo}
            src={new_sidelogo}
            width={700}
            alt="innovat10ns Logo" />
        </div>
        <div className="how-header">
          <p>just how do we 1nn0vate?</p>
        </div>
      </div>
      <div className="how-desc">
        <p>
          We start with a converstion about the problem to create a solution.
        </p>
      </div>
      <div className="how-row">
        <div className="how-column">
          <div className="how-column-left">
            We are a managed services and custom development organization with centuries of
            IT experience focussed on the mortgage and  industry with a unique value proposition:
            nominal development costs.
            <br />
            <br />
            we build high performing, highly scalable custom applications by utilizing a library
            of generics that we reconfigure for each clients workflow, passing the time and cost
            savings on.  we create aplications for minimal upfront costs, and charge per use fee,
            saving and optimizing cashflow.
            <br />
            <br />
            several hundred years combined IT experience,
            Decades of combined mortgage, banking, finance, compliance experience.
          </div>
          <div className="how-column-right">
            <img src={Graphic}
              width={300}
              alt="innovat10ns Logo">
            </img>
          </div>
        </div>
      </div>
    </>
  )
}
