// import TextLogo from "../images/textlogo.svg"
import new_toplogo from "../images/new_toplogo.png"

export default function Home() {
  return (
    <>
      <div className="services-logo">
        <img
          // src={TextLogo}
          src={new_toplogo}
          width={600}
          alt="innovations Logo">
        </img>
      </div>
      <div className="home-desc">
        <h1>Home of the innovat0rs</h1>
      </div>
      <div className="home-body">
        <p>
          We are an eclectic mix of Engineers, Problem Solvers and Creatives (Sometimes in the Same Person) United in Producing High Quality Products that Streamline Complex Business Processes.
        </p>
      </div>
    </>
  )
}

