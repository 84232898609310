// import TextLogo from "../images/textlogo.svg"
import new_textlogo from "../images/new_textlogo.png"

export default function Services() {
  return (
    <>
      <div className="services-logo">
        <img
          // src={TextLogo}
          src={new_textlogo}
          width={600}
          alt="innovations Logo">
        </img>
      </div>
      <div className="services-header">
        <h1>our serv1ces</h1>
        <h2>From The Macro To The Micro We Solve Data Problems With Customizable Solutions.</h2>
      </div>
      <div className="services-row">
        <div className="services-column">
          <div className="services-column-left">
            <ul className="service-column-list">
              <li>Auto encrypt email</li>
              <li>Task based work flows</li>
              <li>Parallel workflows</li>
              <li>Multi-level self redacting securitizing documents</li>
              <li>Sensor based fences</li>
              <li>Transaction billing</li>
              <li>CTO as a Service innovat10ns Execution(CaaSiE)</li>
              <li>CTO (Chief Tacitknowledge Officer)</li>
              <li>Failed Project Recovery</li>
              <li>Visionary Training</li>
              <li>Team Building</li>
              <li>Train the Builders in the innovat10ns Way</li>
              <li>"Theory of Omission" Prevention</li>
              <li>Custom Development</li>
              <li>Rapid solution delivery</li>
              <li>Scalablity</li>
            </ul>
          </div>
          <div className="services-column-right" >
            From the Strategic planning to the Tactical implementation to the daily Execution, let our team of 1nnovat0rs aid yours's with our exclusive Chief Technology Officer as-a-Service (CTOaaS).
            <br />
            <br />
            We build high performing, scalable custom applications by utilizing a library of generics that we reconfigure for each client's workflow, passing the time and cost savings on. By creating applications for minimal upfront costs, and charge per use fee, saving and optimizing cashflow.
            <br />
            <br />
            Amongst our team we have: several hundred years combined IT experience, decades of combined mortgage, banking, finance and compliance experience.
            <br />
            When we take on your project, we shoulder most of the burden and costs of designing and developing your unique solution. While only charging a minimal up-front integration and onboarding fee.
            <br />
            <br />
            Once complete, you are free to use your custom-built solution to your heart's content, and pay only a minimum monthly maintenance fee and per user fee billed on a monthly basis.
            <br />
            <br />
            Monitor your solution's progress with our custom client dashboard, showing you who is involved with your project, its status, questions for you to answer, and see how many days before you can begin using your solution.
          </div>
        </div>
      </div>
    </>
  )
}
