// import textlogo from "../images/textlogo.svg"
import new_toplogo from "../images/new_toplogo.png"

export default function Tenants({ props }) {
    return (
        <>
            <div className="tenants-logo">
                <img 
                    // src={textlogo}
                    src={new_toplogo}
                    width={500}
                    alt="innovat10ns Logo" />
            </div>
            <div className="tenets-header">
                <h1> welcome to innovat10ns</h1>
                <h2> We Start With A Conversation About The Problem To Create A Solution. </h2>
            </div>
            <div className="tenents-desc-parent">
                <ul className="tenants-desc"> innovat10ns (innovations) are:
                    <br/>
                    <br/>
                    <li>altruistic</li>
                    <li>universal</li>
                    <li>diverse</li>
                    <li>ESU (Easy to use, Simple to explain, and Useful)</li>
                    <li>can come from anywhere, even the most unlikely of heroes</li>
                    <li>can be inconvenient</li>
                    <li>does not have to be disruptive; but it most likely is</li>
                    <li>may appear like magic</li>
                    <li>are contemplatively spontaneous</li>
                    <li>breeds innovation, spinoffs are inevitable</li>
                    <li>are collaborative</li>
                </ul>
            </div>
        </>
    )
};
