// import TopLogo from "../images/toplogo.svg"
import new_toplogo from "../images/new_toplogo.png"

export default function Products() {
  return (
    <>
      <div className="product-logo">
        <img
          // src={TopLogo}
          src={new_toplogo}
          width={400}
          alt="innovations Logo" >
        </img>
      </div>
      <div className="products-header">
        <h1>our pr0ducts</h1>
        <h2>Macro to Micro We Solve Data Problems With Customizable Solutions.</h2>
        <h3>Our Products Help Employees Be The Best They Can Be.</h3>
      </div>
      <div className="products-text">
        combine our cutting edge "Airport" technology and an event driven model
      </div>
      <div className="products-list-header">
        <p>multiple ways to implement the event driven model:</p>
        <ul className="products-list">
          <li>UI fired</li>
          <li>client application fired</li>
          <li>webhook</li>
          <li>push notification</li>
          <li>SMS message </li>
          <li>database fired</li>
          <li>external api fired</li>
          <li>internal api fired</li>
          <li>airport event emulation</li>
        </ul>
      </div>
    </>
  )
};
