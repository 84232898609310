// import TextLogo from "../images/textlogo.svg"
// import new_textlogo from "../images/new_textlogo.png"
import new_toplogo from "../images/new_toplogo.png"


export default function Contact() {
  return (
    <>
      <div className="services-logo">
        <img
          // src={TextLogo}
          // src={new_textlogo}
          src={new_toplogo}
          width={600}
          alt="innovations Logo">
        </img>
      </div>
      <div className="contact-desc">
        <h1>c0ntact us</h1>
        <h2>Let's Have That Conversation About Your Problem And Create The Solution.</h2>
      </div>
    </>
  )
}
