import Graphic from "../images/graphic.svg"

export default function About() {
  return (
    <>
      <div className="logo-about-page">
        <img src={Graphic}
          width={300}
          alt="innovations Logo" />
      </div>
      <div className="about-desc">
        <h1>
          all about innovat10ns
        </h1>
        <h1>
          We Start With A Conversation About The Problem To Create A Solution.
        </h1>
      </div>
      <div className="about-body">
        <p>
          We are a managed services and custom development organization with centuries of
          IT experience focussed on the mortgage and  banking industry with a unique value proposition:
          nominal development costs.
        </p>
        <p>
          By partnering with companies to solve complex business challenges by
          providing scalable solution(s) with rapid quality development. We offer an affordable
          pricing structure to ease budgeting. Our team has a combined experience of 150 years
          in technology and your industry. Our passion is helping you succeed!
        </p>
      </div>
    </>
  )
}