// import textlogo from "../images/textlogo.svg"
import new_textlogo from "../images/new_textlogo.png"

export default function How() {
  return (
    <>
      <div className="tenants-logo">
        <img 
          // src={textlogo}
          src={new_textlogo}
          width={700}
          alt="innovations text Logo" />
      </div>
      <div className="industries-header">
        <h1>We Start With A Conversation About The Problem To Create A Solution.</h1>
      </div>
      <div className="industries-row">
        <div className="industries-column">
          <div className="industries-column-right">
            <div className="industries-header-serve">
              1ndustries we serve
              <ul className="industries-body">
                <li>Finance</li>
                <li>Banking</li>
                <li>Mortgage</li>
                <li>Capital Markets</li>
                <li>any organization</li>
                <li>any industry</li>
              </ul>
            </div>
          </div>
          <div className="industries-column-left">
            <div className="industries-use-case">
              Use Cases:
            </div>
            <ul className="industries-use-case-text">
              <li>mortgage loan origination systems</li>
              <li>mortgage onboarding/transfer</li>
              <li>seamless third-party integrations</li>
              <li>mortgage to banking onboarding</li>
              <li><br/></li>
              <li>batch jobs are a thing of the past.</li>
              <li>robot jobs are a thing of the past.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
};
