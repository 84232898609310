// import TextLogo from "../images/textlogo.svg"
import new_textlogo from "../images/new_textlogo.png"

export default function Careers(props) {

  return (
    <>
      <div className="services-logo">
        <img
          // src={TextLogo}
          src={new_textlogo}
          width={1000}
          alt="innovations Logo">
        </img>
      </div>
      <div className="Careers-desc">
        <h1>can you 1nn0vate?</h1>
        <h1>We Offer Customizable Solutions To Data Problems. From The Macro To The Micro.</h1>
      </div>
    </>
  )
}
