// import TextLogo from "../images/textlogo.svg"
import new_textlogo from "../images/new_textlogo.png"

export default function Tv() {
  return (
    <>
      <div className="services-logo">
        <img
          // src={TextLogo}
          src={new_textlogo}
          width={1000}
          alt="innovations Logo">
        </img>
      </div>
      <div className="tv-desc">
        <h1>the tv sh0w</h1>
        <h2>innovat10ns: We Start With The Problem To Find The Solution.</h2>
      </div>
    </>
  )
}